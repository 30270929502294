<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end mb-2"
        >
          <span class="card-title" />
          <b-button
            variant="primary"
            @click.prevent="openModal"
          >
            <span class="text-nowrap">Add Company</span>
          </b-button>
        </b-col>
      </b-row>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows.companies ? rows.companies :0"
        :is-loading="loader"
        :search-options="{
          skipDiacritics: true,
          enabled: true,
          externalQuery: searchTerm }"
        mode="remote"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        @on-column-filter="columnFilter"
        @on-sort-change="sortFn"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Status -->
          <span v-if="props.column.field === 'name'">
            {{ props.row.name }}
            <feather-icon
              :icon="props.row.status === 'active' ? 'CheckIcon' : 'AlertTriangleIcon'"
              size="19"
              :style="props.row.status === 'inactive' ? 'color:#e85151' : 'color:#42f57e'"
            />
          </span>
          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>
          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span class="d-flex justify-content-around">
              <feather-icon
                id="tooltip-target-6"
                color="waning"
                icon="ListIcon"
                class="mr-50 cursor-pointer"
                @click.prevent="$router.push({name:'work.list', query:{company_id:props.row.id}})"
              />
              <b-tooltip
                target="tooltip-target-6"
                triggers="hover"
              >
                Work list
              </b-tooltip>
              <feather-icon
                id="tooltip-target-1"
                color="cyan"
                icon="EyeIcon"
                class="mr-50 cursor-pointer"
                @click.prevent="openEditModal(props.row, true)"
              />
              <b-tooltip
                target="tooltip-target-1"
                triggers="hover"
              >
                View
              </b-tooltip>
              <feather-icon
                id="tooltip-target-2"
                color="dark"
                icon="Edit2Icon"
                class="mr-50 cursor-pointer"
                @click.prevent="openEditModal(props.row, false)"
              />
              <b-tooltip
                target="tooltip-target-2"
                triggers="hover"
              >
                Edit
              </b-tooltip>
              <feather-icon
                id="tooltip-target-3"
                color="red"
                icon="TrashIcon"
                class="mr-50 cursor-pointer"
                @click.prevent="deleteCompany(props.row.id)"
              />
              <b-tooltip
                target="tooltip-target-3"
                triggers="hover"
              >
                Delete
              </b-tooltip>
            </span>
          </span>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10','15','20','25']"
                class="mx-1"
                @change="selectedPage(pageLength)"
              />
              <span class="text-nowrap "> of {{ rows.meta && rows.meta.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="page"
                :total-rows="rows.meta && rows.meta.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
    <!--  company add  modal-->
    <b-modal
      id="modal-add-company"
      ref="company-add-modal"
      :title="disabled ? 'View Company' : (edit?'Edit Company': 'Add Company')"
      ok-disabled
      cancel-disabled
      @hidden="hideModal('modal-add-company')"
    >
      <validation-observer ref="companyAdd">
        <b-form>
          <b-form-group
            label="Company Name"
            label-for="company-name"
          >
            <validation-provider
              #default="{ errors }"
              vid="name"
              name="company name"
              rules="required"
            >
              <b-form-input
                v-model="form.name"
                :disabled="disabled"
                :state="errors.length > 0 ? false:null"
                placeholder="Enter company name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Email"
            label-for="email"
          >
            <validation-provider
              #default="{ errors }"
              vid="email"
              name="email address"
              rules="required"
            >
              <b-form-input
                v-model="form.email"
                :disabled="disabled"
                type="email"
                :state="errors.length > 0 ? false:null"
                placeholder="Enter valid email address"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Phone Number"
            label-for="phone-number"
          >
            <validation-provider
              #default="{ errors }"
              vid="mobile"
              name="phone number"
              rules="required"
            >
              <b-form-input
                v-model="form.mobile"
                :disabled="disabled"
                :state="errors.length > 0 ? false:null"
                placeholder="Enter account number"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Description"
            label-for="description"
          >
            <validation-provider
              #default="{ errors }"
              vid="description"
              name="description"
              rules="required"
            >
              <b-form-textarea
                id="textarea-state"
                v-model="form.description"
                :disabled="disabled"
                :state="errors.length > 0 ? false:null"
                placeholder="Enter at least 10 characters"
                rows="3"
                no-resize
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button
          size="md"
          variant="outline-secondary"
          @click.prevent="hideModal('modal-add-company')"
        >
          {{ disabled ? 'OK' : 'Cancel' }}
        </b-button>
        <b-button
          v-if="disabled"
          size="md"
          variant="outline-primary"
          @click.prevent="disabled=false"
        >
          Edit
        </b-button>
        <b-button
          v-if="!disabled"
          size="md"
          variant="primary"
          @click.prevent="addCompany(edit)"
        >
          Submit
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BBadge, BPagination, BFormSelect, BButton, BRow, BCol, BFormTextarea,
  BFormGroup, BFormInput, BForm, BTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@core/utils/validations/validations'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormSelect,
    BButton,
    BRow,
    BCol,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BForm,
    BTooltip,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      disabled: false,
      required,
      form: {
        id: '',
        name: '',
        email: '',
        mobile: '',
        description: '',
      },
      edit: false,
      loader: false,
      pageLength: 25,
      dir: false,
      columns: [
        {
          label: 'Name',
          field: 'name',
          sortable: true,
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name',
            trigger: 'enter',
          },
        },
        {
          label: 'Email',
          field: 'email',
          sortable: true,
          filterOptions: {
            enabled: true,
            placeholder: 'Search Email',
            trigger: 'enter',
          },
        },
        {
          label: 'Mobile',
          field: 'mobile',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      searchTerm: '',
      page: 1,
      data: {},
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        active : 'light-success',
        inactive     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    rows() {
      return this.$store.getters['user/company/GET_COMPANIES']
    },
  },
  mounted() {
    this.getCompanyList()
  },
  methods: {
    openModal() {
      this.disabled = false
      this.edit = false
      this.$bvModal.show('modal-add-company')
    },
    openEditModal(item, show = false) {
      this.edit = true
      this.form.id = item.id
      this.form.name = item.name
      this.form.email = item.email
      this.form.mobile = item.mobile
      this.form.description = item.description
      this.disabled = show
      this.$bvModal.show('modal-add-company')
    },
    hideModal(modalId) {
      this.$nextTick(() => {
        this.$bvModal.hide(modalId)
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const key in this.form) {
          this.form[key] = ''
        }
      })
    },
    addCompany(edit) {
      if (edit) {
        this.editCompany()
        return
      }

      this.$refs.companyAdd.validate().then(success => {
        if (success) {
          this.$store.dispatch('user/company/storeCompany', this.form)
            .then(() => {
              this.hideModal('modal-add-company')
              this.$refs.companyAdd.reset()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  icon: 'BellIcon',
                  text: 'Company added',
                  variant: 'success',
                },
              })
              this.getCompanyList()
            })
            .catch(error => {
              this.$refs.companyAdd.setErrors(error.response.data.errors)
            })
        }
      })
    },
    editCompany() {
      this.$refs.companyAdd.validate().then(success => {
        if (success) {
          this.$store.dispatch('user/company/editCompany', this.form)
            .then(() => {
              this.hideModal('modal-add-company')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  icon: 'BellIcon',
                  text: 'Company updated',
                  variant: 'success',
                },
              })
              this.edit = false
              this.$refs.companyAdd.reset()
              this.getCompanyList()
            })
            .catch(error => {
              this.$refs.companyAdd.setErrors(error.response.data.errors)
            })
        }
      })
    },
    deleteCompany(item) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('user/company/deleteCompany', item).then(() => {
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'Your file has been deleted.',
              showConfirmButton: false,
              timer: 1500,
            })
            this.getCompanyList()
          })
        }
      })
    },
    columnFilter(params) {
      this.getCompanyList(params.columnFilters)
    },
    getCompanyList(data = null, current_page = 1) {
      this.loader = true
      document.onkeydown = () => false
      this.$store.dispatch('user/company/getCompanyList', {
        params: {
          page: current_page,
          per_page: this.pageLength,
          searchTerm: this.searchTerm,
          name: data ? data.name : '',
          email: data ? data.email : '',
          status: data ? data.status : '',
          sort_by: data ? data.field : '',
          sort_order: data ? data.type : '',
        },
      }).then(() => {
        this.loader = false
        document.onkeydown = () => true
      })
    },
    handleChangePage(page) {
      this.getCompanyList(null, page)
    },
    selectedPage() {
      this.getCompanyList(null, this.page)
    },
    // searchHandler() {
    //   this.getCompanyList(this.page, this.pageLength, this.searchTerm)
    // },
    sortFn(params) {
      this.getCompanyList(params[0])
    },
  },
}
</script>

<style>

</style>
